<h1 mat-dialog-title class="aligned-with-icon">
    <mat-icon class="mr-2 warn-color">warning</mat-icon>
    {{ data.title }}
</h1>
<div mat-dialog-content>
    <p>{{ data.summary }}</p>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Close</button>
</div>
